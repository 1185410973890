export enum AbTestKeys {
	PlpUsaRegions = 'plp_regio_usa',
	PlpOneColumnLayout = '_plp__mobile_1_column_layout',
	pdp_shop_the_look = 'pdp_shop_the_look',
	PromoBanner = 'promo_countdown',
	shopping_cart_pago_a_plazos = 'shopping_cart_pago_a_plazos',
	ShoppingCartSaveForLater = 'shopping_cart_save_for_later',
	Test = 'test',
	PlpFirstFourSegmentation = 'plp_first_four_segmentation',
	GpsMenuSectionName = 'gps_menu_section_name',
}
