'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useEffect, useRef } from 'react'
import { env } from 'utils/envManager'

export function GoogleTagManager(): React.ReactNode {
	const { cookiesConsent } = useCookiesContext()
	const loadedRef = useRef(false)

	useEffect(() => {
		if (!loadedRef.current && typeof cookiesConsent === 'boolean') {
			loadedRef.current = true

			const script = document.createElement('script')
			script.src = env.NEXT_PUBLIC_GTM_SERVER_SIDE
			script.async = true

			document.body.appendChild(script)
		}
	}, [cookiesConsent])

	return null
}
