'use client'

import { useEffect, useState } from 'react'

let isMswLoaded = false

export function MSWWrapper({
	children,
}: {
	readonly children: React.ReactNode
}) {
	const isDevelopment = process.env.NODE_ENV === 'development'
	const isMSW = process.env.NEXT_PUBLIC_MSW === 'enable'

	/**
	 * If MSW is enabled and environment is develop, we need to wait for the mocks to be instantiated before rendering the app
	 * In other scenarios, we can render the app right away
	 */
	const [shouldRender, setShouldRender] = useState(!isDevelopment || !isMSW)

	useEffect(() => {
		async function loadMsw() {
			const { initMocks } = await import('mocker')
			await initMocks()
			setShouldRender(true)
		}

		if (isDevelopment && isMSW && !isMswLoaded) {
			isMswLoaded = true
			loadMsw()
		}
	}, [])

	// Scenario where mocks are not instantiated yet
	if (!shouldRender) {
		return null
	}

	return <>{children}</>
}
